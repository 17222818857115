
// Fonts
//@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

.tooltip_templates { display: none; }


.btn-radius-fix {
    border-radius: 0;
}

.btn-select-fix {
    border-radius: 0;
    border-color: #d2d6de;
    background-color: white;
}